import React from "react"
import styled from "styled-components"
import { glassStyle } from "utils"
import { VerticalSection, Grid, CardLinkOutside } from "components/common"

const HomeIntegrations = ({ hat, title, subtitle, valueProps }) => {
  const renderGrid = () => (
    <Grid
      col={2}
      align="center"
      list={valueProps}
      gap={48}
      width="450px"
      component={(vp) => (
        <CardLinkOutside {...vp} ctasTheme={[{ isLink: true }]} />
      )}
    />
  )

  return (
    <StyledHomeIntegrations>
      <VerticalSection
        hat={hat}
        titleAs="h2"
        title={title}
        subtitle={subtitle}
        grid={renderGrid}
      />
    </StyledHomeIntegrations>
  )
}

const StyledHomeIntegrations = styled.div`
  --tg-max-width: 620px;
  --tg-hat-color: var(--water);

  .sectionBackground {
    /* ${glassStyle};
    border-radius: 0; */
  }

  .gridWrap {
    ul {
      grid-gap: var(--sp-48);
    }
  }
`

export default HomeIntegrations
