import React from "react"
import styled from "styled-components"

import { VerticalSection } from "components/common"

import { CustomersGrid } from "src/sections/customers"

import { useContentfulComponent } from "utils"

const CustomersHero = ({ hat, title, subtitle, components }) => {
  const { customers_grid } = useContentfulComponent(components)

  return (
    <StyledCustomersHero>
      <VerticalSection
        full
        mobileFull
        mobileTgOrder="1"
        mobileCtaOrder="2"
        mobileImageOrder="3"
        hat={hat}
        titleAs="h1"
        title={title}
        subtitle={subtitle}
        grid={() => <CustomersGrid customers={customers_grid.gallery} />}
      />
    </StyledCustomersHero>
  )
}

const StyledCustomersHero = styled.div`
  --section-padding-top: var(--sp-nav);
  --section-padding-left: 0;
  --section-padding-right: 0;

  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-mobile-padding-left: 0;
  --section-mobile-padding-right: 0;

  --tg-max-width: 660px;

  .title {
    max-width: 600px;
  }

  .tgWrap {
    padding: 0 var(--section-mobile-padding);
  }
`

export default CustomersHero
