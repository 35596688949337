import React from "react"
import styled from "styled-components"
// import { device } from "utils"
import { HSection, ValueProp, Grid } from "components/common"

const HomeProcess = ({
  sectionId,
  hatIcon,
  hat,
  title,
  subtitle,
  media,
  valueProps,
}) => {
  return (
    <StyledHomeProcess>
      <HSection
        id={sectionId}
        hatIcon={hatIcon}
        titleAs="h2"
        hat={hat}
        title={title}
        subtitle={subtitle}
        media={media}
        grid={() => (
          <Grid
            // toEdge
            col={1}
            list={valueProps}
            component={(vp) => (
              <ValueProp className="pipeVP" orientation="horizontal" {...vp} />
            )}
          />
        )}
      />
    </StyledHomeProcess>
  )
}

const StyledHomeProcess = styled.div`
  --section-padding-top: 0;
  --section-padding-bottom: 0;
  --section-max-width: 1200px;

  .gridWrap {
    margin-top: var(--sp-32);
  }

  .pipeVP {
    .vp_contentWrap {
      grid-template-columns: 20px auto;
      grid-gap: var(--sp-16);
      align-items: center;
    }
  }
`

export default HomeProcess
