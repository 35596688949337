import React from "react"
import styled from "styled-components"

import { VerticalSection, Grid, FeatureCard } from "components/common"

const HomeProducts = ({ title, subtitle, valueProps, hat }) => {
  return (
    <StyledHomeProducts>
      <VerticalSection
        hat={hat}
        title={title}
        subtitle={subtitle}
        grid={() => (
          <Grid
            toEdge
            col={3}
            gap={24}
            // width="400px"
            list={valueProps}
            component={FeatureCard}
          />
        )}
      />
    </StyledHomeProducts>
  )
}

const StyledHomeProducts = styled.div`
  --tg-max-width: 660px;
  --tg-hat-color: var(--spruce);
  --section-max-width: 1200px;
`

export default HomeProducts
