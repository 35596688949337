import React from "react"
import styled from "styled-components"
import map from "lodash/map"

import { device, glassStyle } from "utils"
import { Media } from "components/common"

import Placeholder from "images/customers/blankCustomer.inline.svg"

const CustomersGrid = ({ customers }) => {
  const placeholder = new Array(7).fill("")

  return (
    <StyledCustomerGrid>
      {map(customers, (icon, idx) => (
        <li className={`item-${idx}`} key={idx}>
          {/* <div style={{ height: "100px" }}>item-{idx}</div> */}
          <Media media={{ desktop: icon }} />
        </li>
      ))}
      {map(placeholder, (i, idx) => (
        <li className={`placeholder p-${idx}`} key={idx}>
          {/* <div style={{ height: "100px" }}>{idx}-p</div> */}
          <Placeholder />
        </li>
      ))}
    </StyledCustomerGrid>
  )
}

const StyledCustomerGrid = styled.ul`
  display: grid;
  grid-gap: var(--sp-8);
  grid-template-columns: repeat(4, 1fr);
  /* transform: translateY(-32px); */

  @media ${device.laptop} {
    grid-auto-flow: column;
    grid-gap: var(--sp-16);
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 1fr);
    transform: translateY(-90px);
  }

  li {
    background: white;
    border-radius: var(--sp-12);
    box-shadow: var(--card-shadow);
    width: 100%;

    /* max-height: 60px;
    @media ${device.laptop} {
      max-height: 100px;
    } */

    img,
    .gatsbyImageWrap,
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      border-radius: var(--sp-12);
      filter: grayscale(1);
      /* object-fit: contain !important; */
    }

    &.placeholder {
      opacity: 0.4;
      ${glassStyle};
      border-radius: var(--sp-12);
      /* border: 1px solid blue; */

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &.p-0 {
      grid-row-start: 1;
      grid-column-start: 1;
    }
    &.p-1 {
      grid-row-start: 2;
      grid-column-start: 1;
    }

    &.p-3 {
      grid-row-start: 1;
      grid-column-start: 2;
      opacity: 0;
    }
    &.p-4 {
      grid-row-start: 13;
      grid-column-start: 2;
    }
    &.p-5 {
      grid-row-start: 8;
      grid-column-start: 1;
    }

    &.p-6 {
      grid-row-start: 1;
      grid-column-start: 3;
      opacity: 0;
    }

    @media ${device.laptop} {
      &.item-5,
      &.item-11,
      &.item-28 {
        grid-row-start: 2;
      }

      &.item-0,
      &.item-18,
      &.item-23,
      &.item-35,
      &.item-41 {
        grid-row-start: 3;
      }

      /* placeholders */

      &.p-0 {
        grid-row-start: 1;
        grid-column-start: 1;
      }
      &.p-1 {
        grid-row-start: 2;
        grid-column-start: 1;
      }
      &.p-2 {
        grid-row-start: 2;
        grid-column-start: 7;
      }
      &.p-3 {
        grid-row-start: 8;
        grid-column-start: 1;
        opacity: 0.4;
      }
      &.p-4 {
        grid-row-start: 8;
        grid-column-start: 5;
        opacity: 0;
      }
      &.p-5 {
        grid-row-start: 8;
        grid-column-start: 4;
        /* opacity: 0; */
        /* visibility: hidden; */
      }
      &.p-6 {
        grid-row-start: 2;
        grid-column-start: 5;
        opacity: 0.4;
      }
    }
  }
`

export default CustomersGrid
