import React from "react"
import { VerticalSection } from "components/common"
import styled from "styled-components"

import { CustomersGrid } from "src/sections/customers"

import { useContentfulComponent } from "utils"

const HomeCustomers = ({ title, components }) => {
  const { customers_grid } = useContentfulComponent(components)

  return (
    <StyledHomeCustomers>
      <VerticalSection
        full
        mobileTgOrder="1"
        mobileCtaOrder="2"
        mobileImageOrder="3"
        title={title}
        titleAs="h6"
        grid={() => <CustomersGrid customers={customers_grid.gallery} />}
      />
    </StyledHomeCustomers>
  )
}

const StyledHomeCustomers = styled.div`
  --tg-max-width: 660px;

  --section-mobile-padding-left: 0;
  --section-mobile-padding-right: 0;

  --section-padding-left: 0;
  --section-padding-right: 0;

  .tgContainer,
  .ctaWrap {
    padding: 0 var(--section-mobile-padding);
    position: relative;
    z-index: 2;
  }
`

export default HomeCustomers
