import React from "react"
import styled from "styled-components"

import { HSection } from "components/common"

const HomeVisualizeData = ({ hatIcon, hat, title, subtitle, media }) => {
  return (
    <StyledHomeVisualizeData>
      <HSection
        hatIcon={hatIcon}
        titleAs="h2"
        hat={hat}
        title={title}
        subtitle={subtitle}
        media={media}
      />
    </StyledHomeVisualizeData>
  )
}

const StyledHomeVisualizeData = styled.div`
  --section-padding-top: 0;
  --section-padding-bottom: 0;
  --tg-hat-color: var(--moss);
  --tg-max-width: 400px;
`

export default HomeVisualizeData
