import React from "react"
import styled from "styled-components"
import { Cta, VerticalSection } from "components/common"
import { device } from "src/utils"

const HomeHero = ({ sectionId, title, subtitle, media, ctas }) => {
  return (
    <StyledHomeHero>
      <VerticalSection
        id={sectionId}
        titleAs="h1"
        title={title}
        subtitle={subtitle}
        subtitleAs="pSmall"
        cta={() => (
          <Cta
            className="homeHero_cta"
            ctas={ctas}
            theme={[{ theme: "default" }, { theme: "alt" }]}
          />
        )}
        media={media}
      />
    </StyledHomeHero>
  )
}

const StyledHomeHero = styled.div`
  --section-padding-top: var(--sp-nav);
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-mobile-padding-left: 0;
  --section-mobile-padding-right: 0;
  --tg-max-width: 900px;

  .subtitle {
    max-width: 800px;
    margin: 0 auto;
  }

  .tgContainer,
  .ctaWrap {
    padding-left: var(--section-mobile-padding);
    padding-right: var(--section-mobile-padding);

    @media ${device.laptop} {
      padding-left: initial;
      padding-right: initial;
    }
  }

  .ctaWrap {
    width: 100%;
    .homeHero_cta {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr;

      @media ${device.laptop} {
        width: auto;
        justify-content: center;
        grid-template-columns: repeat(2, auto);
      }

      a {
        button {
          width: 100%;

          @media ${device.laptop} {
            width: auto;
          }
        }
      }
    }
  }
`

export default HomeHero
