import React from "react"
import styled from "styled-components"

import { Section, Testimonials, TextGroup } from "components/common"

import { useContentfulComponent, useInView } from "utils"

const CustomersTestimonials = ({ title, components }) => {
  const { testimonials } = useContentfulComponent(components)
  const { ref, isVisible } = useInView()

  return (
    <StyledCustomersTestimonials ref={ref}>
      <Section align="center">
        <TextGroup title={title} align="center" />
        <Testimonials testimonials={testimonials} isVisible={isVisible} />
      </Section>
    </StyledCustomersTestimonials>
  )
}

const StyledCustomersTestimonials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  --section-padding-top: 0;
  --section-mobile-padding-top: 80px;

  .testimonials {
    margin-top: var(--sp-32);
    align-self: center;
  }
`

export default CustomersTestimonials
