import React from "react"
import styled from "styled-components"

import { VerticalSection } from "components/common"

const HomeCustomized = ({ hatIcon, hat, title, subtitle, media }) => {
  return (
    <StyledHomeCustomized>
      <VerticalSection
        hatIcon={hatIcon}
        hat={hat}
        titleAs="h2"
        title={title}
        subtitle={subtitle}
        media={media}
        mobileTgOrder="1"
      />
    </StyledHomeCustomized>
  )
}

const StyledHomeCustomized = styled.div`
  --tg-max-width: 600px;
  --tg-hat-color: var(--customize);
  --section-mobile-padding-top: var(--sp-64);
  --section-padding-bottom: var(--sp-160);

  .hatIconWrap {
    margin: 0 auto;
  }

  .imageWrap {
    max-width: 1060px;
    img {
      width: 100%;
    }
  }
`

export default HomeCustomized
