import React, { useState } from "react"
import styled from "styled-components"
import map from "lodash/map"
import { motion } from "framer-motion"

import { Media, HSection, Cta } from "components/common"

import { useContentfulComponent, device } from "utils"

import ExtractionToggle from "components/extraction/ExtractionToggle"

const HomeHeroOld = ({ title, subtitle, components, ctas }) => {
  const toggleOptions = ["PROCESS", "FORMULATE", "FARM", "PACKAGE"]
  const [selected, setSelected] = useState(toggleOptions[0])
  const {
    hero_process_pipe,
    hero_farm_pipe,
    hero_formulate_pipe,
    hero_package_pipe,
  } = useContentfulComponent(components)

  const sectionMinHeight = hero_process_pipe.desktop.file.details.image.height

  const toggleMedia = {
    PROCESS: hero_process_pipe,
    FORMULATE: hero_formulate_pipe,
    FARM: hero_farm_pipe,
    PACKAGE: hero_package_pipe,
  }

  const handleChange = (option) => {
    setSelected(option)
  }

  const renderMedia = () => {
    return map(toggleMedia, (media, key) => (
      <ToggleImage
        key={key}
        selected={key === selected}
        name={key}
        media={media}
      />
    ))
  }

  return (
    <StyledHomeHeroOld sectionMinHeight={sectionMinHeight}>
      <HSection
        titleAs="h1"
        title={title}
        subtitle={subtitle}
        subtitleAs="p"
        cta={() => <Cta className="mobile-hero-cta" ctas={ctas} />}
        custom={() => (
          <ExtractionToggle
            selected={selected}
            options={toggleOptions}
            onChange={handleChange}
          />
        )}
        customMedia={renderMedia}
      />
    </StyledHomeHeroOld>
  )
}

const StyledHomeHeroOld = styled.div`
  --section-padding-top: 0;
  --section-mobile-padding-top: var(--sp-mobile-nav);
  /* --section-max-width: 1200px; */
  --section-max-width: 1176px;

  @media ${device.laptop} {
    --tg-max-width: 520px;
  }

  .half-side-container {
    @media ${device.tablet} {
      grid-gap: 32px;
    }
    @media ${device.laptop} {
      grid-gap: 80px;
    }
    @media ${device.desktop} {
      grid-gap: 176px;
    }

    .horizontalWrap_image {
      @media ${device.laptop} {
        min-height: ${({ sectionMinHeight }) => `${sectionMinHeight}px`};
      }

      .fileImage {
        text-align: center;

        @media ${device.laptop} {
          text-align: initial;
        }
      }
    }
  }

  .extraction-toggle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: var(--sp-24);

    @media ${device.laptop} {
      position: relative;
      bottom: initial;
      left: initial;
      transform: initial;
    }
  }

  .ctaWrap {
    @media ${device.laptop} {
      display: none;
    }
  }

  .customWrap {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--sp-32);

    margin-top: 0;

    @media ${device.laptop} {
      margin-top: var(--sp-32);
    }
  }

  .horizontal_textSection {
    position: initial;
    @media ${device.laptop} {
      max-width: 520px;
    }
  }
`
const ToggleImage = ({ media, selected, name }) => {
  const animate = {
    show: {
      position: "relative",
      opacity: 1,
      visibility: "visible",
    },
    hide: {
      position: "absolute",
      zIndex: -999,
      opacity: 0,
      visibility: "hidden",
    },
  }

  return (
    <motion.div
      className={`toggleImage-${name}`}
      initial="hide"
      animate={selected ? "show" : "hide"}
      transition={
        {
          // duration: 0.1,
          // type: "linear",
        }
      }
      variants={animate}
    >
      <Media critical className="hero_pipe" media={media} />
    </motion.div>
  )
}

export default HomeHeroOld
