import React from "react"
import styled from "styled-components"

import { VerticalSection, Cta, TextGroup, Media } from "components/common"

import { device } from "utils"

const HomeAboutUs = ({ hat, title, subtitle, media, ctas }) => {
  return (
    <StyledHomeAboutUs>
      <VerticalSection
        custom={() => (
          <HomeAboutUsWrap>
            <TextGroup
              align="center"
              hat={hat}
              title={title}
              subtitle={subtitle}
              cta={() => <Cta ctas={ctas} theme={[{ isLink: true }]} />}
              custom={() => <Media media={media} />}
            />
          </HomeAboutUsWrap>
        )}
      />
    </StyledHomeAboutUs>
  )
}

const StyledHomeAboutUs = styled.div`
  --section-padding-top: 0;
  --section-mobile-padding-top: 0;
`

const HomeAboutUsWrap = styled.div`
  --tg-max-width: 550px;
  --tg-grid-gap: var(--sp-24);
  margin: 0 auto;
  margin-top: var(--sp-24);
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  .subtitle {
    grid-row-start: 3;
  }

  .customWrap {
    img {
      width: 100%;

      @media ${device.laptop} {
        width: auto;
      }
    }
  }

  .cta {
    grid-row-start: 4;
    margin-top: 0;
  }
`

export default HomeAboutUs
