import React from "react"
import styled from "styled-components"

import { VerticalSection, Grid, ValueProp, Cta } from "components/common"

import { glassStyle, device } from "utils"

const HomeValueProps = ({ title, subtitle, valueProps, hat, ctas }) => {
  return (
    <StyledHomeValueProps>
      <VerticalSection
        hat={hat}
        title={title}
        subtitle={subtitle}
        grid={() => (
          <Grid
            gap={48}
            list={valueProps}
            component={(vp) => <ValueProp align="center" {...vp} />}
            align="center"
          />
        )}
        cta={() => <Cta ctas={ctas} />}
        mobileTgOrder="1"
        mobileGridOrder="2"
        mobileCtaOrder="3"
        tgOrder="1"
        gridOrder="2"
        ctaOrder="3"
      />
    </StyledHomeValueProps>
  )
}

const StyledHomeValueProps = styled.div`
  --tg-max-width: 660px;
  --tg-hat-color: var(--spruce);
  --section-mobile-padding-top: var(--sp-64);

  --section-padding-bottom: var(--sp-160);
  --section-padding-left: var(--sp-16);
  --section-padding-right: var(--sp-16);

  .sectionBackground {
    ${glassStyle};
    border-radius: 0;
  }

  .gridWrap {
    ul {
      grid-template-columns: repeat(2, minmax(auto, 140px));

      @media ${device.tablet} {
        grid-template-columns: repeat(3, minmax(auto, 150px));
      }

      @media ${device.laptop} {
        /* grid-template-columns: repeat(auto-fill, minmax(auto, 140px)); */

        grid-template-columns:
          minmax(auto, 130px)
          minmax(auto, 110px)
          minmax(auto, 145px)
          minmax(auto, 120px)
          minmax(auto, 145px)
          minmax(auto, 145px)
          minmax(auto, 149px);
      }
    }
  }

  .ctaWrap {
    margin-top: var(--sp-32);

    @media ${device.lapotop} {
      margin-top: var(--sp-48);
    }
  }
`

export default HomeValueProps
