import React from "react"
import styled from "styled-components"

import { HSection } from "components/common"

const HomeOrganizedFinances = ({ hatIcon, hat, title, subtitle, media }) => {
  return (
    <StyledHomeOrganizedFinances>
      <HSection
        id="get-organized"
        hatIcon={hatIcon}
        titleAs="h2"
        hat={hat}
        title={title}
        subtitle={subtitle}
        media={media}
      />
    </StyledHomeOrganizedFinances>
  )
}

const StyledHomeOrganizedFinances = styled.div`
  --tg-hat-color: var(--moss);
  --section-padding-top: 0;
  --section-padding-bottom: 0;

  --tg-max-width: 400px;
`

export default HomeOrganizedFinances
